import React from "react";

interface NumericKeypadProps {
  onComplete: (value: string) => void;
  onBackspace: () => void;
  onClear: () => void;
}

const NumericKeypad: React.FC<NumericKeypadProps> = ({
  onComplete,
  onBackspace,
  onClear,
}) => {
  const handleButtonClick = (value: string) => {
    onComplete(value);
  };

  return (
    <div className="flex justify-center items-center sm-screen">
      {/* Contenedor principal para centrar en la pantalla */}
      <div className="grid grid-cols-3 gap-2 max-w-sm mx-auto">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
          <button
            key={num}
            onClick={() => handleButtonClick(num.toString())}
            className="w-16 h-16 bg-blue-200 text-black font-bold rounded-full hover:bg-blue-300"
          >
            {num}
          </button>
        ))}
        <button
          onClick={onBackspace}
          className="w-16 h-16 bg-red-300 text-black font-bold rounded-full hover:bg-red-400 flex justify-center items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>
        <button
          onClick={() => handleButtonClick("0")}
          className="w-16 h-16 bg-blue-200 text-black font-bold rounded-full hover:bg-blue-300"
        >
          0
        </button>
        <button
          onClick={onClear}
          className="w-16 h-16 bg-red-400 text-white font-bold rounded-full hover:bg-red-600"
        >
          Borrar
        </button>
      </div>
    </div>
  );
};

export default NumericKeypad;
