import React, { useEffect, useState, useCallback, useRef } from "react";

import NumericKeypad from "./NumericKeypad";
import OnScreenKeyboard from "./OnScreenKeyboard";
import CryptoJS from "crypto-js";
import axios from "axios";

declare global {
  interface Window {
    ActiveXObject: any;
  }
  var ActiveXObject: any;
}

const SECRET_KEY = process.env.REACT_APP_CRYPTO_SECRET_KEY;

const encrypt = (user: {
  dni: string;
  nombre: string | undefined;
  apellido: string | undefined;
  edad: number | undefined;
  sexo: string | undefined;
  fechaNacimiento: Date;
  startTime: string;
}) => {
  if (!SECRET_KEY) {
    throw new Error("SECRET_KEY is not defined");
  }
  return CryptoJS.AES.encrypt(JSON.stringify(user), SECRET_KEY).toString();
};

const decryptData = (encryptedData: string) => {
  if (!SECRET_KEY) {
    throw new Error("SECRET_KEY is not defined");
  }
  const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

// Agregar esta función helper para timeout
const withTimeout = (promise: Promise<any>, timeoutMs: number) => {
  let timeoutHandle: NodeJS.Timeout;
  const timeoutPromise = new Promise((_, reject) => {
    timeoutHandle = setTimeout(() => {
      reject(new Error("Timeout"));
    }, timeoutMs);
  });

  return Promise.race([promise, timeoutPromise])
    .then((result) => {
      clearTimeout(timeoutHandle);
      return result;
    })
    .catch((error) => {
      clearTimeout(timeoutHandle);
      throw error;
    });
};

// Función para capitalizar la primera letra de cada palabra
const capitalizeFirstLetter = (text: string) => {
  return text
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

// Función para convertir todo el texto a mayúsculas
const toUpperCase = (text: string) => {
  return text.toUpperCase();
};

const Chatbot: React.FC = () => {
  const [step, setStep] = useState(0);
  const [dni, setDni] = useState("");
  const [userData, setUserData] = useState<any>({});
  const [inputData, setInputData] = useState<{
    nombre?: string;
    apellido?: string;
    edad?: number;
    sexo?: string;
    diaNacimiento?: number;
    mesNacimiento?: number;
    anioNacimiento?: number;
    fechaNacimiento?: Date;
  }>({
    sexo: "",
    fechaNacimiento: undefined,
  });
  const [loading, setLoading] = useState(false);
  const [keyboardVisible] = useState(true);
  const [currentInput, setCurrentInput] = useState<
    keyof typeof inputData | null
  >(null); // Para el campo activo
  const [currentQuestion, setCurrentQuestion] = useState(0); // Índice de pregunta actual
  const [answers, setAnswers] = useState<string[]>([]); // Para almacenar las respuestas
  const [result, setResult] = useState<any>(null); // Para almacenar el resultado de ChatGPT
  const [countdown, setCountdown] = useState<number | null>(null);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [serverError, setServerError] = useState<boolean>(false);

  // Primero, añadir la referencia para el temporizador
  const idleTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  // Agregar un nuevo state para almacenar el buffer del teclado
  // Agregar un nuevo state para almacenar el buffer del teclado
  const [keyboardBuffer, setKeyboardBuffer] = useState<string>("");

  // Modificar la función que maneja el foco
  const handleInputFocus = (field: keyof typeof inputData) => {
    // Limpiar el buffer del teclado
    setKeyboardBuffer("");
    // Establecer el campo activo
    setCurrentInput(field);
  };

  // Modificar resetChatbot sin dependencia de idleTimeout
  const resetChatbot = useCallback(() => {
    setStep(0);
    setDni("");
    setUserData({});
    setInputData({
      sexo: "",
      fechaNacimiento: undefined,
    });
    setCurrentQuestion(0);
    setAnswers([]);
    setResult(null);
    setCountdown(null);

    if (idleTimeoutRef.current) {
      clearTimeout(idleTimeoutRef.current);
      idleTimeoutRef.current = null;
    }
  }, []); // Sin dependencias

  // Modificar resetIdleTimer para usar la referencia
  const resetIdleTimer = useCallback(() => {
    if (idleTimeoutRef.current) {
      clearTimeout(idleTimeoutRef.current);
    }
    idleTimeoutRef.current = setTimeout(resetChatbot, 30000);
  }, [resetChatbot]);

  // Modificar el useEffect del temporizador
  useEffect(() => {
    if (step === 1 || step === 2) {
      resetIdleTimer();

      return () => {
        if (idleTimeoutRef.current) {
          clearTimeout(idleTimeoutRef.current);
          idleTimeoutRef.current = null;
        }
      };
    }
  }, [step, resetIdleTimer]);

  // Temporizador de cuenta regresiva después de mostrar el resultado
  useEffect(() => {
    if (step === 3 && result) {
      setCountdown(15); // 15 segundos de cuenta regresiva
      const countdownInterval = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(countdownInterval);
            resetChatbot();
            return null;
          }
          return (prev || 1) - 1;
        });
      }, 1000);
      return () => clearInterval(countdownInterval);
    }
  }, [step, result, resetChatbot]); // Asegúrate de que solo se ejecute cuando `step`, `result`, o `resetChatbot` cambian

  const handleDNIInput = (value: string) => {
    setDni((prevDni) => prevDni + value);
  };

  const handleDNIBackspace = () => {
    setDni((prevDni) => prevDni.slice(0, -1));
  };

  const handleDNIClear = () => {
    setDni("");
  };
  const handleReturn = () => {
    resetChatbot();
  };

  const validateDNI = (dni: string) => {
    const dniRegex = /^[0-9]{7,8}$/;
    return dniRegex.test(dni);
  };

  // Modificar checkUser
  const checkUser = async () => {
    if (!validateDNI(dni)) {
      setShowErrorModal(true);
      setTimeout(() => {
        setShowErrorModal(false);
        resetChatbot();
      }, 15000);
      return;
    }

    setLoading(true);
    const startTime = new Date().toISOString(); // Registrar timestamp de inicio

    try {
      const response = await axios.get(
        `https://curemd.online/www_idoc/api/users/${dni}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = decryptData(response.data.data);

      // Formatear nombre y apellido
      const formattedNombre = capitalizeFirstLetter(data.nombre);
      const formattedApellido = toUpperCase(data.apellido);

      setUserData({
        ...data,
        nombre: formattedNombre,
        apellido: formattedApellido,
        startTime, // Añadir el tiempo de inicio a los datos del usuario
      });
      setStep(2);
    } catch (error: any) {
      if (error.response && error.response.status >= 500) {
        setServerError(true);
      } else {
        setStep(1);
      }
    } finally {
      setLoading(false);
    }
  };

  // Modificar saveUser
  const saveUser = async () => {
    const {
      nombre,
      apellido,
      edad,
      sexo,
      diaNacimiento,
      mesNacimiento,
      anioNacimiento,
    } = inputData;

    // Validaciones
    if (
      !nombre ||
      !apellido ||
      !edad ||
      !sexo ||
      !diaNacimiento ||
      !mesNacimiento ||
      !anioNacimiento
    ) {
      setErrorMessage(
        "Por favor, completa todos los campos antes de continuar."
      );
      setShowErrorModal(true);
      return;
    }

    const fechaNacimiento = new Date(
      anioNacimiento,
      mesNacimiento - 1,
      diaNacimiento
    );

    // Calcular la edad a partir de la fecha de nacimiento
    const calcularEdad = (fechaNacimiento: Date) => {
      const hoy = new Date();
      let edadCalculada = hoy.getFullYear() - fechaNacimiento.getFullYear();
      const mes = hoy.getMonth() - fechaNacimiento.getMonth();

      if (mes < 0 || (mes === 0 && hoy.getDate() < fechaNacimiento.getDate())) {
        edadCalculada--;
      }
      return edadCalculada;
    };

    const edadCalculada = calcularEdad(fechaNacimiento);

    if (edad !== edadCalculada) {
      setErrorMessage(
        `La edad ingresada no coincide con la fecha de nacimiento. Según la fecha de nacimiento, tu edad es ${edadCalculada} años.`
      );
      setShowErrorModal(true);
      return;
    }

    const startTime = new Date().toISOString(); // Registrar timestamp para usuarios nuevos
    setLoading(true);
    try {
      // Aplicar formato a nombre y apellido
      const formattedNombre = capitalizeFirstLetter(nombre);
      const formattedApellido = toUpperCase(apellido);

      const user = {
        dni,
        nombre: formattedNombre,
        apellido: formattedApellido,
        edad,
        sexo,
        fechaNacimiento,
        startTime, // Enviar el tiempo de inicio al backend
      };
      const encryptedUser = encrypt(user);
      const response = await axios.post(
        "https://curemd.online/www_idoc/api/users",
        {
          encryptedUser,
        }
      );
      const data = decryptData(response.data.data);
      setUserData({
        ...data,
        startTime, // Añadir el tiempo de inicio a los datos del usuario
      });
      setStep(2);
    } catch (error: any) {
      if (error.response && error.response.status >= 500) {
        setServerError(true);
      } else {
        console.error("Error al guardar el usuario:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  // Modificar handleInputChange para usar resetIdleTimer
  const handleInputChange = (field: string, value: string | number | Date) => {
    if (step === 1 || step === 2) {
      resetIdleTimer();
    }

    setInputData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const questions = [
    "¿Has experimentado fiebre alta repentina en los últimos días - escalofríos - piel fría y/o húmeda, palpitaciones aceleradas o malestar general?",
    "¿Sientes dolor intenso de cabeza, especialmente en la frente - detrás de los ojos - has tenido mareos o desmayos?",
    "¿Tienes dolor en las articulaciones y músculos - sientes fatiga o cansancio extremo?",
    "¿Has tenido náuseas o vómitos recientemente - dolores abdominales persistentes - diarrea o pérdida de apetito?",
    "¿Has experimentado erupciones cutáneas desde que comenzó la fiebre - sarpullido rojo -  manchas en la piel o moretones sin causa aparente?",
    "¿Has notado sangrado inusual de encías o nariz?",
    "¿Sabes si hay persona en tu vecindario o familia con diagnóstico reciente de dengue?",
  ];

  // Modificar handleNextQuestion
  const handleNextQuestion = async (answer: string) => {
    if (idleTimeoutRef.current) clearTimeout(idleTimeoutRef.current);
    setAnswers((prev) => [...prev, answer]);

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion((prev) => prev + 1);
    } else {
      // Preparar datos adicionales
      const additionalQuestions = [
        "Vives o trabajas en una zona donde se han reportado casos de dengue recientemente?",
        "Has notado aumento de mosquitos en tu entorno?",
        "Te han picado mosquitos en los ultimos dias?",
      ];

      const additionalAnswers = ["Sí", "Sí", "Sí"];

      const combinedData = [
        ...additionalQuestions.map((question, index) => ({
          question,
          answer: additionalAnswers[index],
        })),
        ...questions.map((question, index) => ({
          question,
          answer: answers[index] || answer,
        })),
      ];

      setLoading(true);
      try {
        const response = await withTimeout(
          axios.post("https://curemd.online/www_idoc/chatbot", {
            dni,
            nombre: userData.nombre,
            apellido: userData.apellido,
            edad: userData.edad,
            sexo: userData.sexo,
            data: combinedData,
            startTime: userData.startTime,
          }),
          90000
        );

        setResult(response.data);
        setStep(3);
      } catch (error) {
        console.error("Error:", error);
        setErrorMessage(
          "Error al procesar las respuestas. Por favor, intente nuevamente."
        );
        setShowErrorModal(true);
      } finally {
        setLoading(false);
      }
    }
  };

  // Modificar handleKeyboardInput para usar el buffer
  const handleKeyboardInput = (value: string) => {
    if (currentInput) {
      if (step === 1 || step === 2) {
        resetIdleTimer();
      }

      // Si el campo es "edad", solo aceptar números
      if (currentInput === "edad") {
        if (/^\d+$/.test(value)) {
          setInputData((prev) => ({
            ...prev,
            [currentInput]: parseInt(value),
          }));
        }
      } else {
        // Para otros campos, concatenar el valor
        setInputData((prev) => ({
          ...prev,
          [currentInput]: (prev[currentInput] || "") + value,
        }));
      }
    }
  };

  // // Modificar handleKeyboardInput también
  // const handleKeyboardInput = (value: string) => {
  //   if (currentInput) {
  //     if (step === 1 || step === 2) {
  //       resetIdleTimer();
  //     }

  //     setInputData((prev) => ({
  //       ...prev,
  //       [currentInput]: (prev[currentInput] || "") + value,
  //     }));
  //   }
  // };

  // Selector de fecha de nacimiento
  // En el renderFechaNacimiento, modifica cómo manejas la selección de la fecha:
  const renderFechaNacimiento = () => (
    <div className="mb-4">
      <label className="block text-gray-700">Fecha de Nacimiento:</label>
      <div className="flex space-x-4">
        {/* Maneja cada cambio de manera independiente */}
        <select
          className="w-1/3 p-2 border rounded-lg"
          onChange={(e) =>
            handleInputChange("diaNacimiento", parseInt(e.target.value))
          }
        >
          <option value="">Día</option>
          {[...Array(31).keys()].map((day) => (
            <option key={day + 1} value={day + 1}>
              {day + 1}
            </option>
          ))}
        </select>
        <select
          className="w-1/3 p-2 border rounded-lg"
          onChange={(e) =>
            handleInputChange("mesNacimiento", parseInt(e.target.value))
          }
        >
          <option value="">Mes</option>
          {[
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
          ].map((mes, index) => (
            <option key={index} value={index + 1}>
              {mes}
            </option>
          ))}
        </select>

        <select
          className="w-1/3 p-2 border rounded-lg"
          onChange={(e) =>
            handleInputChange("anioNacimiento", parseInt(e.target.value))
          }
        >
          <option value="">Año</option>
          {[...Array(101).keys()].map((year) => (
            <option key={1920 + year} value={1920 + year}>
              {1920 + year}
            </option>
          ))}
        </select>
      </div>
    </div>
  );

  const renderSexoSelector = () => (
    <div className="mb-4">
      <label className="block text-gray-700">Sexo:</label>
      <div className="flex space-x-4">
        <button
          className={`w-1/2 py-2 px-4 rounded ${
            inputData.sexo === "masculino"
              ? "bg-blue-500 text-white"
              : "bg-gray-200 text-black"
          }`}
          onClick={() => handleInputChange("sexo", "masculino")}
        >
          Masculino
        </button>
        <button
          className={`w-1/2 py-2 px-4 rounded ${
            inputData.sexo === "femenino"
              ? "bg-blue-500 text-white"
              : "bg-gray-200 text-black"
          }`}
          onClick={() => handleInputChange("sexo", "femenino")}
        >
          Femenino
        </button>
      </div>
    </div>
  );

  // Modificar el componente para incluir el select de edad
  const renderEdadSelector = () => (
    <div className="mb-4">
      <label className="block text-gray-700">Edad:</label>
      <select
        className="w-full p-2 border rounded-lg"
        value={inputData.edad || ""}
        onChange={(e) => handleInputChange("edad", parseInt(e.target.value))}
      >
        <option value="">Seleccione edad</option>
        <option value="0">Menos de 1 año</option>
        {[...Array(120)].map((_, index) => (
          <option key={index + 1} value={index + 1}>
            {index + 1} años
          </option>
        ))}
      </select>
    </div>
  );

  // Teclado en pantalla
  const renderKeyboard = () => (
    <OnScreenKeyboard
      onInput={handleKeyboardInput} // Usa el campo actual para ingresar datos
      onBackspace={() =>
        currentInput &&
        setInputData((prev) => ({
          ...prev,
          [currentInput]: (prev[currentInput] as string).slice(0, -1),
        }))
      }
      onClear={() =>
        currentInput &&
        setInputData((prev) => ({
          ...prev,
          [currentInput]: "",
        }))
      }
      onReturn={handleReturn}
    />
  );

  const printTicket = async () => {
    try {
      const color = getColorByDengueProbability(result.dengueProbability);

      // Datos del ticket
      const ticketData = {
        title: "Clasificación ingreso",
        box: "Dengue",
        fecha: new Date().toLocaleDateString(),
        hora: new Date().toLocaleTimeString(),
        paciente: `${userData.nombre} ${userData.apellido}`,
        dni: dni,
        color: color,
      };

      // Generar el contenido del ticket
      const content = generateTicketContent(ticketData);

      // Enviar solicitud POST al servicio de impresión
      await axios.post("http://localhost:8307/print", { content });
    } catch (error) {}
  };

  useEffect(() => {
    if (result) {
      setShowPrintModal(true);
      // Cerrar automáticamente después de 15 segundos
      const timer = setTimeout(() => {
        setShowPrintModal(false);
        resetChatbot();
      }, 15000);
      return () => clearTimeout(timer);
    }
  }, [result, resetChatbot]);

  const ErrorModal = () => {
    if (!showErrorModal || !errorMessage) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full">
          <h2 className="text-2xl font-bold text-red-600 mb-4">Error</h2>
          <p className="text-lg mb-6">{errorMessage}</p>
          <button
            onClick={() => {
              setShowErrorModal(false);
              // Eliminamos resetChatbot(); para que no reinicie el chatbot
            }}
            className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            Cerrar
          </button>
        </div>
      </div>
    );
  };

  const PrintModal = () => {
    const [isPrinting, setIsPrinting] = useState(false);

    if (!showPrintModal) return null;

    const color = getColorByDengueProbability(result.dengueProbability);

    // Datos del ticket
    const ticketData = {
      title: "Clasificación ingreso",
      box: "Dengue",
      fecha: new Date().toLocaleDateString(),
      hora: new Date().toLocaleTimeString(),
      paciente: `${userData.nombre} ${userData.apellido}`,
      dni: dni,
      color: color,
    };

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full">
          <h2 className="text-2xl font-bold text-blue-600 mb-4">
            {ticketData.title}
          </h2>
          <div className="mb-6 text-left space-y-2 text-center">
            <p>
              <strong>Fecha:</strong> {ticketData.fecha}
            </p>
            <p>
              <strong>Hora:</strong> {ticketData.hora}
            </p>
            <p>
              <strong>Paciente:</strong> {ticketData.paciente}
            </p>
            <p>
              <strong>DNI:</strong> {ticketData.dni}
            </p>
            <p>
              <strong>Clasificación:</strong> {ticketData.color.toUpperCase()}
            </p>
          </div>
          <div className="flex space-x-4">
            <button
              onClick={async () => {
                setIsPrinting(true);
                setShowPrintModal(false); // Cerrar el modal inmediatamente
                await printTicket();
                setTimeout(resetChatbot, 1000);
              }}
              className={`w-1/2 py-2 px-4 rounded ${
                isPrinting
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-blue-500 text-white hover:bg-blue-600"
              }`}
              disabled={isPrinting}
            >
              Imprimir Turno
            </button>
            <button
              onClick={() => {
                setShowPrintModal(false);
                resetChatbot();
              }}
              className="w-1/2 bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    );
  };

  const getColorByDengueProbability = (probability: number) => {
    if (probability <= 30) return "Azul";
    if (probability <= 45) return "Verde";
    if (probability <= 70) return "Amarillo";
    if (probability <= 89) return "Naranja";
    return "Rojo";
  };

  const generateTicketContent = (ticketData: any) => {
    return `
      <!DOCTYPE html>
      <html>
        <head>
          <title>${ticketData.title}</title>
           <style>
      @page {
        size: 79mm 79mm;
        margin: 1mm;
      }
      body {
        margin: 0;
        padding: 0;
        width: calc(79mm - 1mm); /* Ancho total menos márgenes */
        height: calc(79mm - 1mm); /* Alto total menos márgenes */
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .content {
        text-align: center;
        width: 100%;
      }
      h2 {
        font-size: 7mm;
        margin: 0;
      }
      p,
      .large-text {
        font-size: 5mm;
        margin: 0;
      }
      .large-text {
        font-weight: bold;
        font-size: 6mm;
        margin-top: 1mm;
      }
      .footer {
        font-weight: bold;
        margin-top: 2mm;
        font-size: 3mm;
        text-align: center;
        width: 100%;
        border-top: 1px dashed #000;
        padding-top: 2mm;
      }
    </style>
        </head>
        <body>
          <div class="content">
            <h2>${ticketData.title}</h2>
            <p><strong>Paciente:</strong> ${ticketData.paciente}</p>
            <p><strong>DNI:</strong> ${ticketData.dni}</p>
            <p><strong>Fecha:</strong> ${ticketData.fecha}</p>
            <p><strong>Hora:</strong> ${ticketData.hora}</p>
            <p><strong>Clasificación:</p>
            <div class="large-text">${ticketData.color.toUpperCase()}</div>
            <div class="footer">
              <p>Municipalidad de Córdoba</p>
              <p>Secretaría de Salud</p>
            </div>
          </div>
        </body>
      </html>
    `;
  };

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gray-100">
      {/* Encabezado con la imagen de iDoc Bot */}
      <div className="w-full bg-white sticky top-0 p-2 mb-2 flex justify-center items-center">
        <img src="/IDOCLOGO.png" alt="Logo" className="mx-auto h-32" />
      </div>
      {serverError && <ServerErrorModal />}
      <ErrorModal />
      <PrintModal />
      <div className="flex-1 p-2 w-full max-w-xl mx-auto flex flex-col justify-center items-center">
        {loading && (
          <div>
            <p className="text-center text-gray-500">Analizando datos...</p>
            <div className="flex justify-center items-center">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
            </div>
          </div>
        )}

        {!loading && step === 0 && (
          <div className="w-full text-center">
            <h2 className="text-2xl font-bold mb-2">
              Bienvenido, por favor ingrese su DNI
            </h2>
            <div className="text-2xl mb-4 border p-8">{dni || "0"}</div>
            <NumericKeypad
              onComplete={handleDNIInput}
              onBackspace={handleDNIBackspace}
              onClear={handleDNIClear}
            />
            <button
              onClick={checkUser}
              className="mt-4 w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            >
              ENVIAR
            </button>
          </div>
        )}

        {!loading && step === 1 && (
          <div className="w-full">
            <h2 className="text-2xl font-bold text-center mb-4">
              Ingrese sus datos
            </h2>
            <div className="mb-4">
              <label className="block text-gray-700">Nombre:</label>
              <input
                type="text"
                value={inputData.nombre || ""}
                onFocus={() => handleInputFocus("nombre")} // Establece el campo activo
                onChange={(e) => handleInputChange("nombre", e.target.value)}
                className="w-full p-2 border rounded-lg"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Apellido:</label>
              <input
                type="text"
                value={inputData.apellido || ""}
                onFocus={() => handleInputFocus("apellido")} // Establece el campo activo
                onChange={(e) => handleInputChange("apellido", e.target.value)}
                className="w-full p-2 border rounded-lg"
              />
            </div>
            {renderEdadSelector()}
            {renderFechaNacimiento()}
            {renderSexoSelector()}
            <button
              onClick={saveUser}
              className="mt-4 w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
            >
              Guardar
            </button>
            {keyboardVisible && renderKeyboard()}
          </div>
        )}

        {!loading && step === 2 && (
          <div className="w-full text-center">
            <h2 className="text-5xl font-bold mb-12">
              Bienvenido {userData.apellido}, {userData.nombre}{" "}
            </h2>
            <div className="text-3xl mb-12">{questions[currentQuestion]}</div>
            <div className="flex justify-center space-x-12">
              <button
                onClick={() => handleNextQuestion("Sí")}
                className="bg-green-500 text-black font-bold text-4xl py-4 px-8 rounded-lg hover:bg-green-600"
              >
                Sí
              </button>
              <button
                onClick={() => handleNextQuestion("No")}
                className="bg-red-500 text-black font-bold text-4xl py-4 px-5 rounded-lg hover:bg-red-600"
              >
                No
              </button>
            </div>
            <button
              onClick={resetChatbot}
              className="mt-4 bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
            >
              Reiniciar
            </button>
          </div>
        )}

        {!loading && step === 3 && result && (
          <div className="w-full text-center">
            <h2 className="text-2xl font-bold mb-8">Gracias por elegirnos:</h2>
            <p className="text-4xl font-bold mb-8">
              Esperamos nuestra atención haya sido de su agrado.
            </p>

            {countdown && (
              <p className="text-xl font-bold text-gray-500">
                Reiniciando en {countdown} segundos...
              </p>
            )}

            <button
              onClick={resetChatbot}
              className="mt-4 bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
            >
              Reiniciar
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const ServerErrorModal = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = "https://cba.idoc.bot";
    }, 10000); // Esperar 10 segundos antes de redirigir
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full">
        <h2 className="text-2xl font-bold text-red-600 mb-4">
          Problemas Técnicos
        </h2>
        <p className="text-lg mb-6">
          Actualmente estamos experimentando problemas técnicos. Estamos
          trabajando para solucionarlos a la brevedad. Serás redirigido en
          breve.
        </p>
        <button
          onClick={() => (window.location.href = "https://cba.idoc.bot")}
          className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        >
          Ir ahora
        </button>
      </div>
    </div>
  );
};

export default Chatbot;
