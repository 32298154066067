import React from "react";
import "./index.css"; // Asegúrate de que el archivo CSS con Tailwind esté siendo importado
import Chatbot from "./components/Chatbot";

const App: React.FC = () => {
  return (
    <div>
      <Chatbot />
    </div>
  );
};

export default App;
