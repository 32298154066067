import React, { useState } from "react";

interface OnScreenKeyboardProps {
  onInput: (value: string) => void;
  onBackspace: () => void;
  onClear: () => void;
  onReturn: () => void;
}

const OnScreenKeyboard: React.FC<OnScreenKeyboardProps> = ({
  onInput,
  onBackspace,
  onReturn,
}) => {
  const [shift, setShift] = useState(false);
  const [isNumeric, setIsNumeric] = useState(false); // Para alternar entre letras y números

  const handleKeyClick = (key: string) => {
    onInput(shift ? key.toUpperCase() : key.toLowerCase());
  };

  return (
    <div className="flex flex-col items-center space-y-4">
      {!isNumeric ? (
        <>
          {/* Teclado de Letras */}
          <div className="grid grid-cols-10 gap-2">
            {"QWERTYUIOP".split("").map((key) => (
              <button
                key={key}
                onClick={() => handleKeyClick(key)}
                className="w-12 h-12 bg-blue-200 text-black font-bold rounded-lg hover:bg-blue-300"
              >
                {shift ? key : key.toLowerCase()}
              </button>
            ))}
          </div>

          <div className="grid grid-cols-10 gap-2 justify-center">
            {"ASDFGHJKLÑ".split("").map((key) => (
              <button
                key={key}
                onClick={() => handleKeyClick(key)}
                className="w-12 h-12 bg-blue-200 text-black font-bold rounded-lg hover:bg-blue-300"
              >
                {shift ? key : key.toLowerCase()}
              </button>
            ))}
          </div>

          <div className="grid grid-cols-9 gap-2 justify-center">
            <button
              onClick={() => setShift(!shift)}
              className="w-12 h-12 bg-gray-300 text-black font-bold rounded-lg hover:bg-gray-400"
            >
              ⇧
            </button>
            {"ZXCVBNM".split("").map((key) => (
              <button
                key={key}
                onClick={() => handleKeyClick(key)}
                className="w-12 h-12 bg-blue-200 text-black font-bold rounded-lg hover:bg-blue-300"
              >
                {shift ? key : key.toLowerCase()}
              </button>
            ))}
            <button
              onClick={onBackspace}
              className="w-12 h-12 bg-red-300 text-black font-bold rounded-lg hover:bg-red-400"
            >
              ⌫
            </button>
          </div>

          {/* Última fila ajustada */}
          <div className="flex justify-between w-full max-w-lg">
            <button
              onClick={() => setIsNumeric(true)} // Cambia al teclado numérico
              className="w-24 h-12 bg-yellow-300 text-black font-bold rounded-lg hover:bg-yellow-400"
            >
              .?123
            </button>
            <button
              onClick={() => onInput(" ")}
              className="flex-grow h-12 mx-4 bg-blue-200 text-black font-bold rounded-lg hover:bg-blue-300"
            >
              Espacio
            </button>
            <button
              onClick={onReturn}
              className="w-24 h-12 bg-green-500 text-white font-bold rounded-lg hover:bg-green-600"
            >
              Inicio
            </button>
          </div>
        </>
      ) : (
        <>
          {/* Teclado Numérico */}
          <div className="grid grid-cols-3 gap-2">
            {["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"].map((num) => (
              <button
                key={num}
                onClick={() => onInput(num)}
                className="w-12 h-12 bg-blue-200 text-black font-bold rounded-lg hover:bg-blue-300"
              >
                {num}
              </button>
            ))}
          </div>

          <div className="grid grid-cols-3 gap-2 justify-center">
            <button
              onClick={() => setIsNumeric(false)} // Cambia al teclado de letras
              className="w-24 h-12 bg-yellow-300 text-black font-bold rounded-lg hover:bg-yellow-400"
            >
              ABC
            </button>
            <button
              onClick={() => onInput(" ")}
              className="flex-grow h-12 bg-blue-200 text-black font-bold rounded-lg hover:bg-blue-300"
            >
              Space
            </button>
            <button
              onClick={onReturn}
              className="w-24 h-12 bg-green-500 text-white font-bold rounded-lg hover:bg-green-600"
            >
              Return
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default OnScreenKeyboard;
